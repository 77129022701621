import { Logger } from '@eftours/web-logger-typescript';
import { AxiosInstance, AxiosStatic } from 'axios';

// FIXME: This should be centralized. Fix package structure.
const HEADER_KEY = 'X-Correlation-Id';

type CorrelationIdInterceptorOptions = {
  logger: Logger;
  axios: AxiosStatic | AxiosInstance;
  noEmptyCorrelationIds?: boolean;
  persistCorrelationIds?: boolean;
};

/**
 * setCorrelationIdInterceptor adds a `X-Correlation-Id` header value if it is not already present
 * on a given request. Use `noEmptyCorrelationIds` and `persistCorrelationIds` to control
 * logger-level behavior for missing and persisting correlation ids.
 *
 * By default, any request without a correlation id will have one appended to the current headers.
 * It will not be persisted across multiple requests.
 * @param CorrelationIdInterceptorOptions
 */
const setCorrelationIdInterceptor = ({
  logger,
  axios,
  noEmptyCorrelationIds = true,
  persistCorrelationIds = false,
}: CorrelationIdInterceptorOptions): (() => void) => {
  const requestInterceptor = axios.interceptors.request.use((config) => {
    let cid = config?.headers?.[HEADER_KEY];

    if (!cid && noEmptyCorrelationIds) {
      cid = logger.getCorrelationId() || logger.generateCorrelationId();
      config.headers[HEADER_KEY] = cid;

      if (persistCorrelationIds) {
        logger.setCorrelationId(cid);
      }
    }
    return config;
  });

  return () => axios.interceptors.request.eject(requestInterceptor);
};

export type { CorrelationIdInterceptorOptions };
export { setCorrelationIdInterceptor };
