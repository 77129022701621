import React from 'react';
import { Logger } from '@eftours/web-logger-typescript';
import { ReactLogEntry, newReactLogEntry } from './logEntry';

enum LogTypes {
  Displayed = 'FallbackUIDisplayed',
  Error = 'FallbackUIError',
}

type FallbackUIProps = {
  logger: Logger;
  componentName?: string;
  err?: Error;
  meta?: (meta: ReactLogEntry) => ReactLogEntry;
  children: React.ReactNode;
};

const metaNoop = (meta: ReactLogEntry) => meta;

/**
 * FallbackUI is a basic wrapper component to be used in situations where you plan to display
 * fallback UI due to some error. Provide the error and appropriate logger instance, and it will
 * render the provided children when error is defined. This can be used for anything from full
 * page errors to inline status errors, like validation issues.
 *
 * When err is undefined, nothing is rendered.
 */
const FallbackUI = ({ logger, componentName = '', err, meta = metaNoop, children }: FallbackUIProps) => {
  if (err !== undefined) {
    // Try to build out the meta object, but _never_ throw an error
    try {
      const metaObj = meta(
        newReactLogEntry({
          logType: LogTypes.Displayed,
          err: err,
          additional: { componentName },
        })
      );

      logger.error(metaObj);
    } catch (e) {
      // newReactLogEntry might have failed here so we can't call it.
      // This catch is any, but it can safely be assumed to be an Error
      // instance.
      const err = e as Error;
      logger.error({
        componentName,
        logType: LogTypes.Error,
        message: err?.message,
        stack: err?.stack,
      });
    }

    return children;
  }

  return null;
};

export type { FallbackUIProps };
export { FallbackUI };
