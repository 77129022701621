import { Logger as WinstonLogger, LoggerOptions, createLogger as _createLogger } from 'winston';
import { generateCorrelationId as _generateCorrelationId } from './correlationId';

const CORRELATION_ID = 'correlationId';

/**
 * appendLoggerMeta updates the given logger's default meta data with new values. It will
 * impact all future log events.
 */
const appendLoggerMeta = (logger: WinstonLogger) => (meta: Record<string, any>) => {
  logger.defaultMeta = { ...logger.defaultMeta, ...meta };
};

/**
 * getLoggerMeta returns the current meta value in the logger for a key
 */
const getLoggerMeta = (logger: WinstonLogger) => (key: string) => {
  return logger.defaultMeta?.[key];
};

/**
 * generateCorrelationId returns a hash string suitable for a correlation id.
 */
const generateCorrelationId = () => () => _generateCorrelationId();

/**
 * getLoggerCorrelationId returns the current correlation id in the logger
 */
const getLoggerCorrelationId = (logger: WinstonLogger) => (): string | undefined => {
  const cid = getLoggerMeta(logger)(CORRELATION_ID);
  if (cid) {
    return cid as string;
  }

  return cid as undefined;
};

/**
 * setLoggerCorrelationId sets the given correlation id for the logger
 */
const setLoggerCorrelationId = (logger: WinstonLogger) => (id?: string) => {
  appendLoggerMeta(logger)({ [CORRELATION_ID]: id || generateCorrelationId()() });
};

/**
 * getChildLogger wraps `WinstonLogger.child` with `patchWinstonLogger`
 */
// eslint-disable-next-line
const getChildLogger = (logger: WinstonLogger) => (additionalMeta: object) =>
  patchWinstonLogger(logger.child({ ...additionalMeta }));

/**
 * LoggerProxyMethod is a collection of methods that are patched onto a winston Logger.
 * The underlying implementation is not available to extend. Instead we rely on a simple
 * proxy object that allows access to these methods on any logger instance.
 */
const LoggerProxyMethods = {
  appendMeta: appendLoggerMeta,
  getMeta: getLoggerMeta,
  getCorrelationId: getLoggerCorrelationId,
  setCorrelationId: setLoggerCorrelationId,
  generateCorrelationId,
  child: getChildLogger,
};

type LoggerProxyMethodKeys = keyof typeof LoggerProxyMethods;

/**
 * LoggerProxy strongly types the current universe of LoggerProxyMethods so they
 * can easily be appended to the Logger interface.
 */
type LoggerProxy = {
  [Property in keyof typeof LoggerProxyMethods]: ReturnType<typeof LoggerProxyMethods[Property]>;
};

/**
 * Logger is the interface describing a logger instance. It is a composite of winston's Logger
 * and LoggerProxy. When accessing attributes of Logger, winston's native methods are attempted
 * first followed by the proxy methods.
 *
 * @see `patchWinstonLogger`
 */
interface Logger extends LoggerProxy, Omit<WinstonLogger, 'child'> {}

/**
 * patchWinstonLogger wraps a WinstonLogger implementation with a proxy object to
 * introduce additional methods--less code than implementing a manual proxy class.
 * It will attempt to use proxy methods on the logger first, and if they don't
 * exist it will attempt to access native methods.
 * @param logger WinstonLogger
 * @returns Logger
 */
const patchWinstonLogger = (logger: WinstonLogger): Logger => {
  return new Proxy(logger, {
    get: (object, prop) => {
      const maybeProxyMethod = prop as LoggerProxyMethodKeys;
      if (LoggerProxyMethods[maybeProxyMethod]) {
        return LoggerProxyMethods[maybeProxyMethod](object);
      }
      const maybeWinstonMethod = prop as keyof WinstonLogger;
      if (object[maybeWinstonMethod]) {
        return object[maybeWinstonMethod];
      }

      return undefined;
    },
  }) as unknown as Logger;
};

const createLogger = (options?: LoggerOptions) => {
  const logger = _createLogger(options);
  return patchWinstonLogger(logger);
};

export type { Logger };
export { createLogger };
