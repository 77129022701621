import { LogEntry } from 'winston';
import TransportStream from 'winston-transport';
import Rollbar from 'rollbar';

const REQUEST_OBJECT_KEYS = ['headers', 'protocol', 'url', 'method', 'body', 'route', 'user_id', 'user'];

const extractRequestFromMeta = (meta: Record<string, string>): [Record<string, any>, Record<string, any>] => {
  const request: Record<string, any> = {};
  const rest: Record<string, any> = {};

  Object.entries(meta).forEach(([k, v]) => {
    if (REQUEST_OBJECT_KEYS.includes(k)) {
      request[k] = v;
    } else {
      rest[k] = v;
    }
  });

  return [request, rest];
};

type RollbarTransportOptions = {
  rollbarConfiguration: Rollbar.Configuration;
} & TransportStream.TransportStreamOptions;

/**
 * Transport for outputting to Rollbar
 */
class RollbarTransport extends TransportStream {
  rollbar: Rollbar;

  constructor({ rollbarConfiguration, ...options }: RollbarTransportOptions) {
    super(options);
    rollbarConfiguration.logLevel = options.level as Rollbar.Level;
    this.rollbar = new Rollbar(rollbarConfiguration);
  }

  /**
   * Basic logging method exposed to winston. The types for custom transports
   * type info as any, but they are actually a winston.LogEntry.
   */
  log(info: any, callback: () => void) {
    // FIXME: We should be using triple-beam to reference the uniq symbols to
    // name the level and message field. However, using these symbols throws:
    // Type 'unique symbol' cannot be used as an index type.
    //
    // These symbols are defined here:
    // https://github.com/winstonjs/triple-beam/blob/master/index.js
    const { level, message, ...srcMeta } = info as LogEntry;
    const [requestMeta, meta] = extractRequestFromMeta(srcMeta);

    this.rollbar.log(message, requestMeta, meta, (rollbarErr) => {
      if (rollbarErr) {
        this.emit('warn', rollbarErr);
      } else {
        this.emit('logged', { level, message, meta });
      }

      if (callback) {
        callback();
      }
    });
  }
}

export type { RollbarTransportOptions };
export { RollbarTransport };
