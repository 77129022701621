import { Logger } from '@eftours/web-logger-typescript';
import { LogTypes, AxiosLogEntry, newAxiosLogEntry } from './logEntry';

type InterceptError = Record<string, any> | undefined;

/**
 * interceptErrorToError translates a mystery error shape into an
 * Error interface
 */
const interceptErrorToError = (err: InterceptError): Error => ({
  name: err?.name || 'Unknown Error',
  message: err?.message,
  stack: err?.stack,
});

/**
 * InterceptErrorMatcher is a basic interface for matching errs returned in response
 * interceptors to a log activity. This needs to exist because the err data model
 * is bonkers. Inlining this code is a mess.
 */
interface InterceptErrorMatcher {
  match: (err: InterceptError) => boolean;
  meta: (err: InterceptError) => AxiosLogEntry;
  log: (err: InterceptError, meta: AxiosLogEntry, fallBackLogger: Logger) => void;
}

/**
 * unknownErrorMatcher is a `InterceptErrorMatcher` for errors we can't match otherwise.
 *
 * It should be last in your checking chain.
 */
const unknownErrorMatcher: InterceptErrorMatcher = {
  match: () => true,
  meta: (err) =>
    newAxiosLogEntry({
      logType: LogTypes.UnknownError,
      err: interceptErrorToError(err),
    }),
  log: (_, meta, fallBackLogger) => {
    fallBackLogger.error(meta);
  },
};

/**
 * requestErrorMatcher is a `InterceptErrorMatcher` for errors that have no response data.
 *
 * This will log as much information as possible with data that may or may not be available.
 */
const requestErrorMatcher: InterceptErrorMatcher = {
  match: (err) => !err?.response,
  meta: (err) =>
    newAxiosLogEntry({
      logType: LogTypes.RequestError,
      err: interceptErrorToError(err),
      additional: {
        url: err?.config?.url,
        method: err?.config?.method,
        status: null,
      },
    }),
  log: (err, meta, fallBackLogger) => {
    err?.loggerProfiler ? err.loggerProfiler.done(meta) : fallBackLogger.error(meta);
  },
};

/**
 * responseUnknownErrorMatcher is a `InterceptErrorMatcher` for errors that are missing the original
 * request config on the error. This shouldn't be possible, but needs to be protected against.
 */
const responseUnknownErrorMatcher: InterceptErrorMatcher = {
  match: (err) => err?.response && !err.response.config,
  meta: (err) =>
    newAxiosLogEntry({
      logType: LogTypes.ResponseError,
      err: interceptErrorToError(err),
      additional: {
        status: null,
      },
    }),
  log: (_, meta, fallBackLogger) => {
    fallBackLogger.log(meta);
  },
};

/**
 * apiResponseError is a `InterceptErrorMatcher` for standard non-2XX responses. 4XX get warnings (except 401).
 * 5XX are errors.
 *
 * FIXME: Mapping status to error probably needs to be configured as certain errors are expected/unexpected based
 * on customer/application type in the 4XX range.
 */
const apiResponseError: InterceptErrorMatcher = {
  match: (err) => err?.response?.config,
  meta: (err) => {
    return newAxiosLogEntry({
      logType: LogTypes.Call,
      err: interceptErrorToError(err),
      additional: {
        url: err?.response?.config?.url,
        method: err?.response?.config?.method,
        status: err?.response?.status,
        params: err?.response?.config?.params,
      },
    });
  },
  log: (err, meta, fallBackLogger) => {
    err?.loggerProfiler
      ? err.loggerProfiler.done({ ...fallBackLogger.defaultMeta, ...meta })
      : fallBackLogger.log(meta);
  },
};

const InterceptErrorMatchers: InterceptErrorMatcher[] = [
  apiResponseError,
  responseUnknownErrorMatcher,
  requestErrorMatcher,
  unknownErrorMatcher,
];

export type { InterceptError };
export { InterceptErrorMatchers };
