import React from 'react';
import { Logger } from '@eftours/web-logger-typescript';
import { newReactLogEntry } from './logEntry';

// UNKNOWN_COMPONENT is the component name returned when a component cannot
// be identified from the stack.
const UNKNOWN_COMPONENT = 'Unknown Component' as const;

enum LogTypes {
  UncaughtException = 'ErrorBoundaryUncaughtException',
  Error = 'ErrorBoundaryError',
}

/**
 * COMPONENT_STACK_REGEX is the regex used to parse out the first component name
 * found in a React.ErrorInfo object.
 *
 * The current regex matches the first line in a string that looks like
 * "at ComponentName (created by App)".
 */
const COMPONENT_STACK_REGEX = /at\s+([^\s]+)\s+.+/i;

type ErrorBoundaryState = {
  hasError?: boolean;
  err?: Error;
};

type ErrorBoundaryProps = {
  logger: Logger;
  renderError: (state: ErrorBoundaryState) => React.ReactNode;
  children: React.ReactNode;
};

/**
 * ErrorBoundary is a basic implementation of a React ErrorBoundry. The provider logger
 * logs an error message when an exception is caught.
 *
 * See: https://reactjs.org/docs/error-boundaries.html
 *
 * ErrorBoundary will not catch errors in:
 * - Event handlers
 * - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  /**
   * parseComponentName returns the first component name found in given React.ErrorInfo
   * instance. If no component is found, `Unknown Component` is used.
   */
  private parseComponentName(info: React.ErrorInfo) {
    const match = info.componentStack.match(COMPONENT_STACK_REGEX);
    // The first match is the entire line "at ComponentName (created by App)" and
    // the second match is the actual component name.
    if (match && match.length > 1) {
      return match[1];
    }

    return UNKNOWN_COMPONENT;
  }

  /**
   * getDerivedStateFromError is used to trigger state changes to render fallback UI
   * after an error.
   *
   * See: https://reactjs.org/docs/error-boundaries.html
   */
  static getDerivedStateFromError(err: Error) {
    // Set state to call renderError on next render
    return { hasError: true, err };
  }

  /**
   * componentDidCatch is used to log caught errors. It is a requirement of the React error
   * boundary implementation.
   *
   * See: https://reactjs.org/docs/error-boundaries.html
   */
  componentDidCatch(err: Error, info: React.ErrorInfo) {
    // Log error to our logger. Use the component stack to construct the message.
    const componentName = this.parseComponentName(info);
    const meta = newReactLogEntry({
      err,
      logType: LogTypes.UncaughtException,
      additional: {
        componentName,
        componentStack: info.componentStack,
      },
    });

    this.props.logger.error(meta);
  }

  render() {
    // If an error is present, render with renderError
    if (this.state.hasError) {
      return this.props.renderError(this.state);
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
