import TransportStream from 'winston-transport';
import { LoggerOptions, format, transports } from 'winston';
import { logTypeFormatter } from './logType';
import { RollbarTransport } from './transports/rollbar';
import { BeaconTransport, BeaconTransportOptions } from './transports/beacon';

/**
 * LoggerEnvironment describes the host environment for a given logger
 */
enum LoggerEnvironment {
  DEVELOPMENT = 'development',
  TEST = 'test',
  QA = 'qa',
  STAGING = 'staging',
  PRODUCTION = 'production',
  UNKNOWN = 'unknown',
}

/**
 * LoggerConfig defines the required parameters needs to create
 * a logger instance.
 */
type LoggerConfig = {
  rollbar: {
    accessToken: string;
  };
  environment: LoggerEnvironment;
  service: string;
  beacon?: BeaconTransportOptions['beaconConfiguration'];
};

interface LoggerConfigProvider {
  (conf: LoggerConfig): LoggerOptions;
}

enum LoggerConfigs {
  fallback = 'fallback',
  dev = 'dev',
  serverless = 'serverless',
  browser = 'browser',
}

const getDefaultMetaFromConfig = (configType: LoggerConfigs, { environment, service }: LoggerConfig) => ({
  environment,
  service,
  config: configType,
});

/**
 * newRollbarTransport is a helper method for creating a new RollbarTransport
 * instance to be used in a logger configuration.
 */
const newRollbarTransport = ({ rollbar, environment }: LoggerConfig, level = 'error') => {
  const rollbarConf = { environment, ...rollbar };

  return new RollbarTransport({
    rollbarConfiguration: rollbarConf,
    level: level,
    format: format(logTypeFormatter)(),
  });
};

const newConsoleTransport = () => {
  return new transports.Console({
    format: format.combine(format.timestamp(), format(logTypeFormatter)(), format.json()),
  });
};

const newBeaconTransport = ({ beacon }: Required<Pick<LoggerConfig, 'beacon'>>) => {
  return new BeaconTransport({
    beaconConfiguration: beacon,
    format: format.combine(format.timestamp(), format(logTypeFormatter)(), format.json()),
  });
};

/**
 * getFallbackConf returns a console only logging configuration that should be used in case
 * of failed logger init
 */
const getFallbackConf = (conf: LoggerConfig): LoggerOptions => {
  return {
    level: 'error',
    transports: [newConsoleTransport()],
    defaultMeta: getDefaultMetaFromConfig(LoggerConfigs.fallback, conf),
    exitOnError: false,
  };
};

/**
 * getDevConfig returns a console only logging configuration to be used with
 * local development.
 */
const getDevConfig = (conf: LoggerConfig): LoggerOptions => {
  return {
    level: 'debug',
    transports: [newConsoleTransport()],
    defaultMeta: getDefaultMetaFromConfig(LoggerConfigs.dev, conf),
    exitOnError: false,
  };
};

/**
 * getServerlessConfig is a logging configuration to be used with serverless
 * services like AWS Lambda or Fargate.
 *
 * Console logging is in logstash format and Rollbar is used for any errors or above.
 * Rollbar's access token must be passed as an override.
 */
const getServerlessConfig = (conf: LoggerConfig): LoggerOptions => {
  return {
    level: 'info',
    transports: [newConsoleTransport(), newRollbarTransport(conf, 'error')],
    defaultMeta: getDefaultMetaFromConfig(LoggerConfigs.serverless, conf),
    exitOnError: false,
  };
};

/**
 * getBrowserConfig is a default logging configuration for a logger used
 * in the browser.
 */
const getBrowserConfig = (conf: LoggerConfig): LoggerOptions => {
  const transports: TransportStream[] = [newRollbarTransport(conf, 'error')];
  const beacon = conf.beacon;
  if (beacon?.endpoint) {
    transports.push(newBeaconTransport({ beacon }));
  }

  return {
    level: 'info',
    transports,
    defaultMeta: getDefaultMetaFromConfig(LoggerConfigs.browser, conf),
    exitOnError: false,
  };
};

// Defines the ConfigProviders for development, serverless, and browser.
const configs: Record<LoggerConfigs, LoggerConfigProvider> = {
  fallback: getFallbackConf,
  dev: getDevConfig,
  serverless: getServerlessConfig,
  browser: getBrowserConfig,
};

export type { LoggerConfig, LoggerConfigProvider };
export { LoggerEnvironment, configs };
