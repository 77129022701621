import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Logger } from '@eftours/web-logger-typescript';
import { setRequestLoggingInterceptors } from './requests';
import { setCorrelationIdInterceptor, CorrelationIdInterceptorOptions } from './correlationId';

type CreateAxiosClientOptions = {
  serviceName: string;
  logger: Logger;
  config?: AxiosRequestConfig;
  using?: (config?: AxiosRequestConfig) => AxiosInstance;
  correlationIdOptions?: Pick<CorrelationIdInterceptorOptions, 'noEmptyCorrelationIds' | 'persistCorrelationIds'>;
};

/**
 * createAxiosClient returns a new axios instance based on the config. `serviceName` is used internally
 * to suffix all logTypes with a given serviceName. This makes metrics filtering much easier as you
 * can create graphs based on things like `AxiosCallFoo`.
 *
 * @example
 * const axios = createAxiosClient('Foo', logger);
 * axios.post('/some/path');
 */
const createAxiosClient = ({
  serviceName,
  logger,
  config = {},
  correlationIdOptions,
  using = axios.create,
}: CreateAxiosClientOptions): AxiosInstance => {
  const ins = using(config);
  setRequestLoggingInterceptors(logger, ins, { serviceName });
  setCorrelationIdInterceptor({ logger, axios: ins, ...correlationIdOptions });

  return ins;
};

export { createAxiosClient };
export { setCorrelationIdInterceptor } from './correlationId';
export { setRequestLoggingInterceptors as useAxiosInterceptors, setRequestLoggingInterceptors } from './requests';
