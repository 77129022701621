import { LoggerConfig, LoggerEnvironment } from './config';

/**
 * NodeEnvVar is a enum of the env vars expected to configure a logger running in
 * a node env.
 */
enum NodeEnvVar {
  LOGGER_SERVICE = 'LOGGER_SERVICE',
  LOGGER_ENVIRONMENT = 'LOGGER_ENVIRONMENT',
  LOGGER_ROLLBAR_ACCESS_TOKEN = 'LOGGER_ROLLBAR_ACCESS_TOKEN',
}

/**
 * ReactAppEnvVar is a enum of the env vars expected to configure a logger running in
 * a React browser env.
 */
enum ReactAppEnvVar {
  LOGGER_SERVICE = 'REACT_APP_LOGGER_SERVICE',
  LOGGER_ENVIRONMENT = 'REACT_APP_LOGGER_ENVIRONMENT',
  LOGGER_ROLLBAR_ACCESS_TOKEN = 'REACT_APP_LOGGER_ROLLBAR_ACCESS_TOKEN',
  LOGGER_BEACON_ENDPOINT = 'REACT_APP_LOGGER_BEACON_ENDPOINT',
  LOGGER_BEACON_DEBOUNCE = 'REACT_APP_LOGGER_BEACON_DEBOUNCE',
  LOGGER_BEACON_BATCH_LIMIT = 'REACT_APP_LOGGER_BEACON_BATCH_LIMIT',
}

/**
 * AvailableEnvVars is a convenience type to represent the possible enum keys
 * for env vars.
 */
type AvailableEnvVars = keyof typeof NodeEnvVar | keyof typeof ReactAppEnvVar;

/**
 * EnvToConfigMap is a type connecting an enum key to a conf transform.
 */
type EnvToConfigMap = Record<AvailableEnvVars, (val: string, conf: Partial<LoggerConfig>) => void>;

/**
 * EnvToConfigMapper is an instance of EnvToConfigMap for the existing env vars defined by
 * AvailableEnvVars. Any missing keys will result in a type error.
 */
const EnvToConfigMapper: EnvToConfigMap = {
  LOGGER_SERVICE: (val, conf) => (conf.service = val),
  LOGGER_ENVIRONMENT: (val, conf) => (conf.environment = val as LoggerEnvironment),
  LOGGER_ROLLBAR_ACCESS_TOKEN: (val, conf) => (conf.rollbar = { accessToken: val }),
  LOGGER_BEACON_ENDPOINT: (val, conf) => (conf.beacon = { ...(conf.beacon || {}), endpoint: val }),
  LOGGER_BEACON_DEBOUNCE: (val, conf) =>
    (conf.beacon = { ...(conf.beacon || { endpoint: '' }), debounce: Number(val) }),
  LOGGER_BEACON_BATCH_LIMIT: (val, conf) =>
    (conf.beacon = { ...(conf.beacon || { endpoint: '' }), batchLimit: Number(val) }),
};

/**
 * configFromEnv creates a LoggerConfig from process.env. The current environment type (server vs browser)
 * is detected by testing for the browser first. No validation occurs during configFromEnv is an empty
 * config can be returned.
 */
const configFromEnv = (): Partial<LoggerConfig> => {
  const conf: Partial<LoggerConfig> = {};
  const envType = process.env[ReactAppEnvVar.LOGGER_SERVICE] ? ReactAppEnvVar : NodeEnvVar;

  // There's not a super clean way to iterate through the enum key/values here.
  // The EnvToConfigMapper object is used to avoid dupe references to named env
  // vars.
  Object.keys(envType).forEach((k) => {
    const envKey = k as keyof typeof envType;
    const envVal = process.env[envType[envKey]];

    if (envVal) {
      EnvToConfigMapper[envKey](envVal, conf);
    }
  });

  return conf;
};

export { configFromEnv };
