import { LogEntry, newLogEntry, NewLogEntryProps } from '@eftours/web-logger-typescript';

interface ReactLogEntry extends LogEntry {
  url?: string;
  stack?: string;
  language?: string;
  userAgent?: string;
  componentName?: string;
  componentStack?: string;
}

type ReactLogEntryOptions = NewLogEntryProps<ReactLogEntry>;

const newReactLogEntry = ({ logType, err, additional }: ReactLogEntryOptions): ReactLogEntry => {
  const entry: Partial<ReactLogEntry> = {};
  // Capture current
  if (window && window.location) {
    entry.url = window.location.href;
  }

  // If navigator is present, pull data from that
  if (navigator) {
    entry.language = navigator.language;
    entry.userAgent = navigator.userAgent;
  }

  return newLogEntry<ReactLogEntry>({ logType, err, additional: { ...entry, ...additional } });
};

export type { ReactLogEntry };
export { newReactLogEntry };
