import { LogEntry } from 'winston';
import TransportStream from 'winston-transport';
import { isBrowser } from '../runtime';

const DEFAULT_BATCH_LIMIT = 20;
const DEFAULT_DEBOUNCE = 1000 * 10; // 10 seconds
const MAX_STACK_SIZE = 250;

type BeaconConfiguration = {
  endpoint: string;
  batchLimit?: number;
  debounce?: number;
};

type BeaconTransportOptions = {
  beaconConfiguration: BeaconConfiguration;
} & TransportStream.TransportStreamOptions;

class BeaconTransport extends TransportStream {
  private stack: LogEntry[];

  private endpoint: string;
  private batchLimit: number;
  private debounce: number;
  private currentTimeout?: number;
  private sendBeacon: (url: string | URL, data: string) => boolean;

  constructor({ beaconConfiguration, ...options }: BeaconTransportOptions) {
    super(options);
    const { endpoint, batchLimit = DEFAULT_BATCH_LIMIT, debounce = DEFAULT_DEBOUNCE } = beaconConfiguration;

    this.endpoint = endpoint;
    this.batchLimit = batchLimit;
    this.debounce = debounce;
    this.stack = [];

    if (isBrowser()) {
      this.sendBeacon = window.navigator.sendBeacon.bind(window.navigator);
      this.start();
    } else {
      this.sendBeacon = () => false;
    }
  }

  private sendAll() {
    while (this.stack.length > 0) {
      this.send();
    }
  }

  private send() {
    const messageStack = this.stack.splice(0, this.batchLimit);
    if (messageStack.length < 1) {
      return;
    }

    // FIXME: Data loss can happen right here, but memory leaks can't as a result.
    const queued = this.sendBeacon(this.endpoint, JSON.stringify(messageStack));
    if (!queued) {
      this.emit('error', new Error('sendBeacon returned false. Stack not queued.'));
    }
  }

  private next() {
    this.currentTimeout = window.setTimeout(() => {
      this.send();
      this.next();
    }, this.debounce);
  }

  private start() {
    window.document.addEventListener('visibilitychange', () => {
      if (window.document.visibilityState === 'hidden') {
        this.sendAll();
        window.clearTimeout(this.currentTimeout);
      }
    });
    this.next();
  }

  log(info: any, callback: () => void) {
    // This is a LogEntry for all intents and purposes
    const logEntry = info as LogEntry;

    // Try to serialize and add to the stack to send when we're ready.
    try {
      // Put the newest value on top
      this.stack.unshift(logEntry);

      // If we've reached our max stack size, purge older events.
      // This is to protect endpoints from runaway loggers.
      const discard = this.stack.splice(MAX_STACK_SIZE);
      if (discard.length) {
        this.emit('warn', `${discard.length} events discarded`);
      }

      this.emit('logged', logEntry);
    } catch (e) {
      this.emit('warn', e);
    }

    if (callback) {
      callback();
    }
  }
}

export type { BeaconTransportOptions };
export { BeaconTransport };
