/**
 * isBrowser is a helper to determine whether or not this code is being
 * executed in a browser.
 */
const isBrowser = () => typeof window !== 'undefined' && typeof window.document !== 'undefined';

/**
 * isProd is a helper to determine whether or not this code is being executed
 * in a production env.
 */
const isProd = () => {
  // HACK: True story: using process?.env?.NODE_DEV was throwing process is undefined errors.
  return process !== undefined && process.env !== undefined && process.env.NODE_ENV === 'production' ? true : false;
};

export { isBrowser, isProd };
