import { TransformFunction } from 'logform';

// LogType is a primitive type to describe the "category"
// FIXME: What is this future??
type LogType = string;

// logTypeFormatter is a basic winston log formatter that preprends any message
// with the value in logType. logType is meant to be an eventually standardized
// field to make searching/filtering logs easy.
//
// If logType is missing, it is set to Unknown.
const logTypeFormatter: TransformFunction = (info) => {
  if (!info.logType) {
    info.logType = 'Unknown';
  }

  if (info.message) {
    info.message = `${info.logType}: ${info.message}`;
  } else {
    info.message = info.logType;
  }

  return info;
};

export type { LogType };
export { logTypeFormatter };
