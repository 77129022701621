import { AxiosStatic, AxiosInstance, AxiosRequestConfig } from 'axios';
import winston from 'winston';
import { Logger } from '@eftours/web-logger-typescript';
import { InterceptError, InterceptErrorMatchers } from './errors';
import { LogTypes, newAxiosLogEntry } from './logEntry';

interface InterceptedAxiosRequestConfig extends AxiosRequestConfig {
  loggerProfiler?: winston.Profiler;
}

type RemoveInterceptorCallback = () => void;

type UseAxiosInterceptorsOptions = {
  serviceName?: string;
};

const setRequestLoggingInterceptors = (
  logger: Logger,
  axios: AxiosStatic | AxiosInstance,
  opts?: UseAxiosInterceptorsOptions
): RemoveInterceptorCallback => {
  // Instance-specific logtype helper
  const resolveLogType = (logType: string) => `${logType}${opts?.serviceName || ''}`;

  // Registers a request interceptor and captures the return value so we can unregister it later
  const requestIntercept = axios.interceptors.request.use(
    // On every outgoing request add the logger profiler
    (config) => {
      const intercepted = config as InterceptedAxiosRequestConfig;
      intercepted.loggerProfiler = logger.startTimer();

      return intercepted;
    },
    // On any request that fails (no response?)
    // FIXME: We need to figure out when this actually happens. The types are useless.
    (err) => {
      const meta = newAxiosLogEntry({
        logType: resolveLogType(LogTypes.RequestError),
        err: err,
      });
      logger.error(meta);

      return Promise.reject(err);
    }
  );

  // Registers a response interceptor and captures the return value so we can unregister it later
  const responseIntercept = axios.interceptors.response.use(
    (resp) => {
      // Grab the original request config we maybe attached a profiler to
      const req = resp.config as InterceptedAxiosRequestConfig;

      // If the request config isn't present or loggerProfiler isn't set, just return the resp.
      if (!req || !req.loggerProfiler) {
        return resp;
      }

      // Winston always expects a message, and in this case it's not what's important--so we use
      // something easy for searching
      //
      // FIXME: req.data is not included right now due to potiential PII we don't want in logs.
      req.loggerProfiler.done(
        newAxiosLogEntry({
          logType: resolveLogType(LogTypes.Call),
          additional: {
            ...logger.defaultMeta,
            url: req.url,
            method: req.method?.toUpperCase(),
            params: req.params,
            status: resp.status,
            level: 'info',
          },
        })
      );

      return resp;
    },

    // FIXME: This is a mess because err is any and we need to figure out what's actually being
    // returned here before doing anything. This is called for any non-2XX status code. Because
    // this return can literally be >3 different data shapes, we use a priority-based matcher to
    // determine how to handle the error.
    (err: InterceptError) => {
      for (const matcher of InterceptErrorMatchers) {
        try {
          // If we don't can't handler this, continue.
          if (!matcher.match(err)) continue;

          // Build the meta data, and then log it. Two-step it to allow us to add additional
          // meta later.
          const meta = matcher.meta(err);
          meta.logType = resolveLogType(meta.logType);
          matcher.log(err, meta, logger);

          // Return the promise.
          return Promise.reject(err);
        } catch (e) {
          logger.error('AxiosInterceptorError:', e);
          continue;
        }
      }

      // If this code is ever executed, we didn't match anything.
      logger.error('AxiosInterceptorNoMatchesError', err);

      return Promise.reject(err);
    }
  );

  return () => {
    axios.interceptors.request.eject(requestIntercept);
    axios.interceptors.response.eject(responseIntercept);
  };
};

export { setRequestLoggingInterceptors as useAxiosInterceptors, setRequestLoggingInterceptors };
